import { createElement, FC } from 'react';
import { useContentBlockTheme, ContentBlockTheme } from './content-block.theme';
import {
  StyledContentBlock,
  StyledContentBlockContainer,
  CollapsingMarginHack
} from './content-block.ccm.css';

export const LargeSpace = 'large';
export const SmallSpace = 'small';
export const NoSpace = 'none';

export interface ContentBlockProps {
  /**
   * What background color to use
   *
   * @default white
   */
  backgroundColor?: keyof ContentBlockTheme['backgroundColors'];

  /**
   * Should this be full width on large screens.
   *
   * Usually this is only true for the first content block on a page,
   * like a Hero Image
   *
   * @default false
   */
  fullWidth?: boolean;

  /**
   * How much top space should be set before content begins inside the block?
   *
   * @default LargeSpace
   */
  topSpace?: typeof NoSpace | typeof SmallSpace | typeof LargeSpace;

  /**
   * How much bottom space should be set after content ends inside the block?
   *
   * Note: If your last element inside the block has margin-bottom, then it will
   * collapse with whatever margin is set here, it will not stack.
   *
   * @default SmallSpace
   */
  bottomSpace?: typeof NoSpace | typeof SmallSpace;

  /**
   * Use a bottom divider for this content block
   *
   * @default false
   */
  bottomDivider?: boolean;
}

const defaultColor: keyof ContentBlockTheme['backgroundColors'] = 'white';

export const ContentBlock: FC<ContentBlockProps> = ({
  fullWidth = false,
  bottomDivider = false,
  topSpace = LargeSpace,
  bottomSpace = SmallSpace,
  backgroundColor = defaultColor,
  children
}) => {
  const { backgroundColors, dividerColor } = useContentBlockTheme();
  const color =
    backgroundColors[backgroundColor] || backgroundColors[defaultColor];
  return (
    <StyledContentBlockContainer.div
      $color={color}
      $inset={!fullWidth}
      $divider={bottomDivider}
      $dividerColor={dividerColor}
    >
      <CollapsingMarginHack.div />
      <StyledContentBlock.div
        $topSpaceSmall={topSpace === SmallSpace}
        $topSpaceLarge={topSpace === LargeSpace}
        $bottomSpace={bottomSpace === SmallSpace}
      >
        {children}
      </StyledContentBlock.div>
      <CollapsingMarginHack.div />
    </StyledContentBlockContainer.div>
  );
};
