
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.CollapsingMarginHack_98de11d4d4164143:before{content:"_";line-height:0;color:transparent;display:block;overflow:hidden}.StyledContentBlockContainer_64af33369c24c3f9{background-color:var(--color_6c0d6c35437922d0);margin-left:auto;margin-right:auto;overflow-y:auto;overflow-x:hidden}.StyledContentBlockContainer_64af33369c24c3f9.divider_affb52658ece7256{border-bottom:16px solid var(--dividerColor_8bd4f31965ed66e1)}.StyledContentBlock_dd8a9d60bc7e2d7b{margin:0}.StyledContentBlockContainer_64af33369c24c3f9.inset_1e472ae587a59778>.StyledContentBlock_dd8a9d60bc7e2d7b{margin-left:16px;margin-right:16px}.StyledContentBlock_dd8a9d60bc7e2d7b.topSpaceSmall_89ce52131e152579{margin-top:16px}.StyledContentBlock_dd8a9d60bc7e2d7b.topSpaceLarge_4e075dab6cc76515{margin-top:32px}.StyledContentBlock_dd8a9d60bc7e2d7b.bottomSpace_7bac99a941dcc303{margin-bottom:16px}@media screen and (min-width:600px){.StyledContentBlockContainer_64af33369c24c3f9.inset_1e472ae587a59778{max-width:664px}.StyledContentBlockContainer_64af33369c24c3f9.inset_1e472ae587a59778>.StyledContentBlock_dd8a9d60bc7e2d7b{margin-left:32px;margin-right:32px}}`;
styleInject(_css)

/** CollapsingMarginHack Props */
export type CollapsingMarginHackCCM = {
  /** CollapsingMarginHack Component Custom Properties */
  // No custom properties found

  /** CollapsingMarginHack Modifier Flags */
  // No modifiers classes found
};
/** Base CollapsingMarginHack component */
export const CollapsingMarginHack: ComponentCreator<CollapsingMarginHackCCM> = createComponentCreator({
  "name": "CollapsingMarginHack",
  "base": "CollapsingMarginHack_98de11d4d4164143",
  "prop": {},
  "mod": {}
});


/** StyledContentBlockContainer Props */
export type StyledContentBlockContainerCCM = {
  /** StyledContentBlockContainer Component Custom Properties */
  '$color': string;
  '$dividerColor': string;

  /** StyledContentBlockContainer Modifier Flags */
  '$divider'?: boolean;
  '$inset'?: boolean;
};
/** Base StyledContentBlockContainer component */
export const StyledContentBlockContainer: ComponentCreator<StyledContentBlockContainerCCM> = createComponentCreator({
  "name": "StyledContentBlockContainer",
  "base": "StyledContentBlockContainer_64af33369c24c3f9",
  "prop": {
    "$color": "--color_6c0d6c35437922d0",
    "$dividerColor": "--dividerColor_8bd4f31965ed66e1"
  },
  "mod": {
    "$divider": "divider_affb52658ece7256",
    "$inset": "inset_1e472ae587a59778"
  }
});


/** StyledContentBlock Props */
export type StyledContentBlockCCM = {
  /** StyledContentBlock Component Custom Properties */
  // No custom properties found

  /** StyledContentBlock Modifier Flags */
  '$topSpaceSmall'?: boolean;
  '$topSpaceLarge'?: boolean;
  '$bottomSpace'?: boolean;
};
/** Base StyledContentBlock component */
export const StyledContentBlock: ComponentCreator<StyledContentBlockCCM> = createComponentCreator({
  "name": "StyledContentBlock",
  "base": "StyledContentBlock_dd8a9d60bc7e2d7b",
  "prop": {},
  "mod": {
    "$topSpaceSmall": "topSpaceSmall_89ce52131e152579",
    "$topSpaceLarge": "topSpaceLarge_4e075dab6cc76515",
    "$bottomSpace": "bottomSpace_7bac99a941dcc303"
  }
});

