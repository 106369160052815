import { createUseThemeHook } from 'legos/theme';

// Augment the global BulkLegoTheme interface to support our lego theme
declare module 'legos/theme' {
  interface BulkLegoTheme {
    contentBlock: ContentBlockTheme;
  }
}

export interface ContentBlockTheme {
  backgroundColors: {
    primary: string;
    positive: string;
    white: string;
    lightNeutral: string;
    neutral: string;
    darkNeutral: string;
  };
  dividerColor: string;
}

const [ContentBlockThemeProvider, useContentBlockTheme] = createUseThemeHook(
  'contentBlock',
  (global): ContentBlockTheme => ({
    // Create defaults for your theme from the global lego theme color palette
    backgroundColors: {
      primary: global.default.color500,
      positive: global.positive.color500,
      white: '#FFFFFF',
      lightNeutral: global.neutral.color50,
      neutral: global.neutral.color200,
      darkNeutral: global.neutral.color800
    },
    dividerColor: global.neutral.color100
  })
);

export { ContentBlockThemeProvider, useContentBlockTheme };
